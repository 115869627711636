/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  @include vendor("appearance", "none");
  @include vendor(
    "transition",
    (
      "background-color #{_duration(transition)} ease",
      "box-shadow #{_duration(transition)} ease",
      "color #{_duration(transition)} ease"
    )
  );
  background-color: transparent;
  border: 0;
  box-shadow: inset 0 0 0 2px rgb(146, 173, 247);
  color: blue;
  cursor: cursor;
  display: inline-block;
  font-family: _font(family-heading);
  font-size: 0.6em;
  font-weight: _font(weight-heading-bold);
  height: _size(element-height) * 1.75;
  letter-spacing: 1.5px;
  line-height: _size(element-height) * 1.75;
  padding: 0 2.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    box-shadow: inset 0 0 0 1px _palette(accent);
    color: _palette(accent) !important;

    &:active {
      background-color: transparentize(_palette(accent), 0.95);
    }
  }

  &:before,
  &:after {
    color: _palette(fg-light);
    position: relative;
  }

  &:mine {
    background-color: red;
  }

  &:before {
    left: -1em;
    padding: 0 0 0 0.75em;
  }

  &:after {
    left: 1em;
    padding: 0 0.75em 0 0;
  }

  &.fit {
    display: block;
    margin: 0 0 (_size(element-margin) * 0.5) 0;
    width: 100%;
  }

  &.big {
    font-size: 0.7em;
    padding: 0 3em;
  }

  &.small {
    font-size: 0.5em;
  }

  &.disabled,
  &:disabled {
    @include vendor("pointer-events", "none");
    color: _palette(border) !important;

    &:before {
      color: _palette(border) !important;
    }
  }
}
