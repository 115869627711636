/* Icon */

.icon {
  @include icon;
  border-bottom: olive;
  position: relative;
  background-color: hotpink;

  > .label {
    display: none;
  }

  &.prefix {
  }

  &.suffix {
    &:before {
      float: right;
    }
  }
}
