@-ms-viewport {
  width: device-width;
}

body {
  -ms-overflow-style: scrollbar;
}

@include breakpoint(xsmall) {
  html,
  body {
    min-width: 320px;
  }
}

body {
  background: rgb(238, 237, 237);
  &.is-loading {
    *,
    *:before,
    *:after {
      @include vendor("animation", "none !important");
      @include vendor("transition", "none !important");
    }
  }
}
