/* Resume */

#resume {
  .link-to {
    position: relative;
    top: -10em;
  }

  .link-container h4 {
    text-decoration: none;
    border-bottom: none;
    display: inline;

    a {
      padding: 4em;
      padding-top: 00em;
      margin: 0;
    }
  }

  .courses {
    padding-top: 3em;
    .title {
      text-align: center;
    }
    .course-list {
      text-align: center;

      h4 {
        white-space: nowrap;
      }
    }
  }

  .skills {
    font-size: large;
    font-family: "Times New Roman", Times, serif;
    font-style: inherit;

    margin-top: 0em;
    margin-bottom: 5em;
    color: blue;
    .title {
      text-align: center;
      color:double-button p {
        font-size: 0.9em;
        color: blue;
      }
    }
  }
  .certifications {
    padding-top: 4em;
    padding-bottom: 4em;

    margin-top: 4em;
    margin-bottom: 9em;
    margin: 2em 0;
    font-family: "Times New Roman", Times, serif;

    font-size: 0.9em;
    font-weight: 400;
    .title {
      text-align: center;
      font-size: large;
    }
  }
}

.education {
  padding-top: 4em;
  padding-bottom: 4em;
  font-size: large;
  margin-top: 6em;
  margin-bottom: 9em;
  margin: 2em 0;
  .title {
    text-align: center;
  }
  .degree {
    text-align: center;
  }
  .school {
    text-align: center;
  }
}

.daterange {
  margin-bottom: 0.2em;
}

.degree-container {
  h4 {
    font-weight: normal;
  }

  .degree {
    margin-bottom: 0.5em;
    text-transform: none;
    font-family: "Times New Roman", Times, serif;

    font-size: 0.9em;
    font-weight: 400;
    letter-spacing: 0.1em;
    margin-top: -1em;
  }

  .school {
    text-transform: none;
    padding-top: 0.3em;
    margin-bottom: 1em;
  }
}

.courses .course-number {
  font-family: "Times New Roman";
  display: inline;
}
.courses .course-name {
  display: inline;
  font-family: "Times New Roman", Times, serif;

  font-size: 0.9em;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 2.5;
  margin-top: -1em;
  text-transform: uppercase;
}

.courses ul li {
  display: inline;
}

.course-dot {
  display: inline;
  // display: none;
}

@include breakpoint(small) {
  .course-dot {
    display: none;
  }
  .course-container a {
    display: block;
  }
}
