//* Box */

.box {
  @include vendor("appearance", "none");
  @include vendor(
    "transition",
    (
      "background-color #{_duration(transition)} ease",
      "box-shadow #{_duration(transition)} ease",
      "color #{_duration(transition)} ease"
    )
  );
  background-color: white;
  border: 0;
  box-shadow: inset 0 0 0 6px _palette(border);
  color: black;
  cursor: cursor;
  display: inline-block;
  font-family: "Verdana";
  font-size: 0.6em;
  font-weight: _font(weight-heading-bold);
  height: _size(element-height) * 1.9;
  letter-spacing: 0.8px;
  line-height: _size(element-height) * 1.75;
  padding: 0 5.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  > :last-child,
  > :last-child > :last-child,
  > :last-child > :last-child > :last-child {
    margin-bottom: 0;
  }

  &.alt {
    border: 0;
    border-radius: 0;
    padding: 0;
  }
}
