// About page (/about)

.markdown {
  p {
    margin: auto;
  }

  h1 {
    font-size: 1.3em;
    margin-top: 1em;
  }
}
