/* Footer */

#footer {
  .icons {
    color: _palette(fg-light);
  }

  .copyright {
    color: _palette(fg-light);
    font-family: _font(family-heading);
    font-size: 0.6em;
    font-weight: _font(weight-heading);
    letter-spacing: _font(kerning-heading);
    text-transform: uppercase;
  }

  body.single & {
    text-align: center;
  }
}
